import request from "@/utils/request";

// 登录
export function mobile(data) {
    return request({
        url: '/usercenter/login/mobile',
        method: 'post',
        data
    })
}
export function logout(data) {
    return request({
        url: '/usercenter/logout',
        method: 'post',
        data
    })
}

//账号密码登录
export function account(data) {
    return request({
        url: '/usercenter/login/account',
        method: 'post',
        data
    })
}
//发送验证码
export function sendVerifyCode(data) {
    return request({
        url: '/usercenter/sendVerifyCode',
        method: 'post',
        data
    })
}
//校验验证码
export function checkVerifyCode(data) {
    return request({
        url: '/usercenter/checkVerifyCode',
        method: 'post',
        data
    })
}
//获取登录二维码
export function getQrcodeTicket(data) {
    return request({
        url: '/usercenter/wechat/mp/getQrcodeTicket',
        method: 'get',
        params: data
    })
}
//检查用户是否扫码
export function checkSubscribe(data) {
    return request({
        url: '/usercenter/wechat/mp/checkSubscribe',
        method: 'get',
        params: data
    })
}

//检查用户是否扫码
export function getUserInfo(data) {
    return request({
        url: '/usercenter/getUserInfo',
        method: 'get',
        params: data
    })
}
//展示企业账号信息  
export function getAccountInfos(data) {
    return request({
        url: '/usercenter/getAccountInfos',
        method: 'get',
        params: data
    })
}
//换绑手机  
export function changeMobile(data) {
    return request({
        url: '/usercenter/change/mobile',
        method: 'post',
        data
    })
}
//更换密码
export function changePass(data) {
    return request({
        url: '/usercenter/change/password',
        method: 'post',
        data
    })
}

// 绑定手机号
export function thirId(data) {
    return request({
        url: '/usercenter/bind/mobile/thirId',
        method: 'post',
        data
    })
}
// 绑定手机号
export function businessServiceList(data) {
    return request({
        url: '/usercenter/businessService/pageService',
        method: 'post',
        data
    })
}